import React from "react";
import Header from "./components/Header";
import Form from "./components/Form";
import Timeline from "./components/Timeline";
import { Routes,Route } from "react-router-dom";
import ImageList from "./components/featchingImages";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import greetsuLogo from "./styles/greetsu-favicon.svg"



function App() {
  return (
    <div className="app">
      <ToastContainer
       
      />
      <Header />
      <Routes>
      <Route path="/" element={<Form />} />
      {/* <Route path="/" element={<ImageList/>} /> */}
      <Route path="/:coupleId" element={<Timeline />} />
      </Routes>

      <a href="https://www.greetsu.com" target="_blank" rel="noopener noreferrer" className="redirect_greetsu">
        <img src={greetsuLogo} alt="greetsu" />
        <div className="redirect__text">
          <p className="text__top">from team</p>
          <p className="text__bottom">GreetsU</p>
        </div>
      </a>
    </div>
  );
}

export default App;
