import React, { useEffect } from "react";
import { useState } from "react";
import "../styles/Form.css";

import superbase, { SUPABASE_URL } from "../config/superbase";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { cardio } from 'ldrs'
import { Helmet } from "react-helmet";




export let docID;

const Form = () => {
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [coupleName,setCoupleName] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [coupleImage, setCoupleImage] = useState(null);
  const [event1, setEvent1] = useState("");
  const [event1Date, setEvent1Date] = useState("");
  const [loader, setLoader] = useState(false);
  const [milestones, setMilestones] = useState([{event1:'', event1Date:''}]);
  const [initialMilestones, setInitialMilestones] = useState([]);
  const [userId, setUserId] = useState(v4());
  const [show, setShow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMilestone, setSelectedMilestone] = useState("");

  docID = userId;
  cardio.register()




  const navigate = useNavigate();

  useEffect(() => {
    const fetchMilestones = async () => {
      const response = await superbase.from("MilestonesList").select("*")
      setInitialMilestones(response.data);
      // console.log("Initial Milestones:", initialMilestones);
    };
    fetchMilestones();
  }, []);
  // console.log("initialMilestones", initialMilestones);

  const handleImageChange = (e, setImage) => {
  const file = e.target.files[0];
  if (file) {
    // Check file type
    if (!file.type.startsWith('image')) {
      toast.error('Please upload an image file');
      return;
    }

    // Check file size
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSizeInBytes) {
      toast.error('File size exceeds 5MB. Please choose a smaller file');
      return;
    }

    // If all checks pass, update the image state
    setImage(file);
  }
};

const handleImag1Change = (e) => {
  handleImageChange(e, setImage1);
};

const handleImag2Change = (e) => {
  handleImageChange(e, setImage2);
};

const handleCpleImgChange = (e) => {
  handleImageChange(e, setCoupleImage);
};


  // adding data to the superbase database
  const handleSubmit = async () => {

    // null checks

    if (!name1 || !name2 || !image1 || !image2 || !event1 || !event1Date) {

      let errorMessage = "Please fill fields of : ";
      if (!name1) errorMessage += "Your Name, ";
      if (!name2) errorMessage += "Partner Name, ";
      if (!image1) errorMessage += "Your Image, ";
      if (!image2) errorMessage += "Partner Image, ";
      if (!event1 || !event1Date) errorMessage += "Milestone fields, ";
      toast.error(errorMessage.slice(0, -2)); // Remove the last comma and space
      return;
    }

    const iteratorLen = event1.length>=event1Date.length ? event1.length : event1Date.length;
    for (let i = 0; i < iteratorLen; i++) {
      if (!event1[i] && event1Date[i]) {
        // console.log(`event ${event1[i]} date ${event1Date[i]}`)
        toast.error(`Please fill the Data for Milestone ${i+1} `);
        return;
      }
      if (event1[i] && !event1Date[i]) {
        // console.log(`event ${event1[i]} date ${event1Date[i]}`)

        toast.error(`Please fill the date for milestone ${i+1}`);
        return;
      }
    }

    if(event1.length > event1Date.length || event1.length < event1Date.length){
      toast.error('Please fill respective fields of Milestones')
      return;
    }

    const milestonesarrayLength = milestones.length
    if(milestonesarrayLength<2){
      toast.error('Add atleast two milestones !')
      return
    }

    // const storage = superbase.storage;

    const image1Ref = image1.name + v4();
    const image2Ref = image2.name + v4();
    const image3Ref = coupleImage?.name + v4();

    setLoader(true);

    try {
      // Upload images to Superbase Storage
      const image1Upload = await superbase.storage
        .from("images")
        .upload(image1Ref, image1);
      const image2Upload = await superbase.storage
        .from("images")
        .upload(image2Ref, image2);
      const image3Upload = await superbase.storage
        .from("images")
        .upload(image3Ref, coupleImage);

      const BASE_URL_IMAGES = `${SUPABASE_URL}/storage/v1/object/public/images/`;

      const url1 = BASE_URL_IMAGES + image1Ref;
      const url2 = BASE_URL_IMAGES + image2Ref;
      const url3 = coupleImage ? BASE_URL_IMAGES + image3Ref : null;
      // console.log("Signed URLs:", url1, url2, url3);

      const tempName1 = name1.trim().split(' ')[0];
      const tempName2 = name2.trim().split(' ')[0];

      // console.log('trimmed name1 and name 2', tempName1, tempName2)

      // Store data in Superbase Database
      const { data, error } = await superbase
      .from("CoupleInfo")
      .insert({
        coupleId: userId,
        name1: tempName1,
        name2: tempName2,
        coupleName:coupleName,
        image1: url1,
        image2: url2,
        coupleImage: url3,
      })
      .select("*");

      if (error) {
        console.error("error from CoupleInfo", error);
        toast.error("Error in uploading data, please try again");

        setTimeout(() => {
          setEvent1("");
          setEvent1Date("");
          setCoupleName("");
          setName1("");
          setName2("");
          setImage1("");
          setImage2("");
          setCoupleImage("");
          setMilestones([{event1:'', event1Date:''}]);
          setLoader(false);
        },3000);
       
      
        // window.location.reload();
        return;
      }

      // if(data) {
      //   console.log("data from CoupleInfo", data);
      // }

      // return;

      for (let i = 0; i < event1.length; i++) {
        const event = event1[i];
        const date = event1Date[i];

        const { data: eventData, error: eventError } = await superbase
          .from("MilestonesInRelation")
          .insert({
            Milestone: event,
            Date: date,
            CoupleId: userId,
          });

        if (eventError) {
          throw new Error(
            "Error inserting event data into milestonesInRelation: " +
              eventError.message
          );
        }

        // console.log("Inserted event:", eventData);
      }

      setLoader(false);
      navigate(`/${userId}`);
    } catch (error) {
      toast.error(error.message)
      console.log("Error:", error.message);
    }
  };

  const handleAddMilestone = () => {
    if(milestones.length===11){
      toast.info('Maximum 10 Milestones are Allowed')
      return; 
    }
    setMilestones([...milestones, { event: "", date: "" }]);
    // console.log("milestones", milestones);
  };

  const handleMilestoneChange = (index, field, value) => {
    // console.log("index , field , value", index, field, value);

    const updateEvent = [...event1];
    const updateEvent1Date = [...event1Date];

    if (field === "event") {
      updateEvent[index] = value;
      setEvent1(updateEvent);
      // console.log("milestones", event1);
    }
    if (field === "date") {
      updateEvent1Date[index] = value;
      setEvent1Date(updateEvent1Date);
      // console.log("milestones", event1Date);
    }
  };
  // console.log("event1", event1);
  // console.log("event1Date", event1Date);

  function handleSearch(e) {
    // console.log('handlesearch called')
    const searchValue = e.target.value;
    setSearchText(searchValue);

    const results = performSearch(searchValue);
    setSearchResults(results);
    // console.log('search result', searchResults)
  }

  function performSearch(searchValue) {
    if(initialMilestones?.length===0 || !initialMilestones){
      toast.error('Error to featching Milestones');
    }
    
    const filteredMilestones =
      initialMilestones &&
      initialMilestones.filter((milestone) =>
        milestone.Milestones.toLowerCase().includes(searchValue.toLowerCase())
      );

      // console.log('filtered milestones', filteredMilestones)

    return filteredMilestones;
  }

  function handlemilestoneSelect(milestone, index) {
    // console.log('handlemilestones', milestone)
    const updateEvent = [...event1];
    updateEvent[index] = milestone;
    setEvent1(updateEvent);
    setSearchText("");
    setSearchResults([]);
    setShow(false);
  }
  

  function handleAdd() {
    if (searchText) {
      console.log(
        "cheack",
        !initialMilestones?.some(
          (milestone) => milestone?.Milestones === searchText
        )
      );
      if (
        initialMilestones &&
        !initialMilestones?.some(
          (milestone) => milestone.Milestones === searchText
        )
      ) {
        setInitialMilestones([
          ...initialMilestones,
          { Milestones: searchText },
        ]);
        // upload to supabase
        const { data, error } = superbase
          .from("MilestonesList")
          .insert({
            Milestones: searchText,
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            toast.error(error)
            console.log(error);
          });
        // console.log("Inserted Milestone:", data);
      }
    }

    setSelectedMilestone(searchText);
    setEvent1([...event1, searchText]);
    setSearchText("");
    setSearchResults([]);
    setShow(false);
  }

  useEffect(() => {
    setShow(Array(milestones.length).fill(false));
  }, [milestones,event1,selectedMilestone]);

  const handleFocus = (index) => {
    const updatedShow = [...show];
    updatedShow[index] = true;
    setShow(updatedShow);
  };

  function showDropDown(index) {

    
    // console.log('show index', show[index])
    return show[index] ? (
        <div className="event-dropdown">
        <div className="search">
          <AiOutlineSearch className="search-btn" />
          <input
            type="text"
            id="milestone"
            placeholder="Search"
            value={searchText}
            onChange={handleSearch}
            autoFocus
            required
          />
        </div>

        {searchText.length === 0 ? (
          <ul className="search-list">
            {initialMilestones?.map((milestone) => (
              <li
                key={milestone.id}
                onClick={() =>
                  handlemilestoneSelect(milestone?.Milestones, index)
                }
              >
                {milestone?.Milestones}
              </li>
            ))}
          </ul>
        ) : searchResults?.length > 0 ? (
          <ul className="search-list">
            {searchResults?.map((milestone) => (
              <li
                key={milestone?.id}
                onClick={() =>
                  handlemilestoneSelect(milestone?.Milestones, index)
                }
              >
                {milestone?.Milestones}
              </li>
            ))}
          </ul>
        ) : (
          <ul className="search-list">
            <li onClick={() => handleAdd(index)}>Add new milestone</li>
          </ul>
        )}
      </div>
      ) : null;
  }

  const handleDeleteMilestone = (index) => {

    const milestonesarrayLength = milestones.length
    // console.log(milestonesarrayLength)

    if(milestonesarrayLength<=2){
      toast.info('At least Two Milestones are Required')
      return;
    }
    const updatedMilestones = [...milestones];
    updatedMilestones.splice(index, 1); // Remove the milestone at the specified index
    setMilestones(updatedMilestones);
  
    // Optionally, you can also remove associated data like event name and date
    const updatedEvent1 = [...event1];
    updatedEvent1.splice(index, 1);
    setEvent1(updatedEvent1);
  
    const updatedEvent1Date = [...event1Date];
    updatedEvent1Date.splice(index, 1);
    setEvent1Date(updatedEvent1Date);
  };
  

  return (
    <>
    <Helmet>
      

    <meta
    name="keywords" 
    content="Loveto, Love Timeline, Love Journey, Create love memory, Valentine gift, Anniversary gift, Suprise, adorable present, Couples Goal, Gift for Girlfriend, Gift for Boyfriend, Gift for Wife, Gift for Husband"/>
   

    <meta property="og:title"
    content="Loveto - Create your Love Timeline" />


    <meta property="og:type"
    content="website" />
    </Helmet>


<div className="form-container">
      {loader ? (
        // <SyncLoader color={"#2A2F4F"} loading={loader} size={25} style={{}} />
              <l-cardio
        size="60"
        stroke="3"
        speed="3.8" 
        color="#8f617e" 
      ></l-cardio>
      ) : (
        <div className="form">
          <h2>Create your Love Timeline</h2>
          <div className="section-info">
            <h2 style={{position:'relative', right:'0', fontSize:'1.5rem'}}>Add your details</h2>
          <label>
            Your Name
            <input
              type="text"
              placeholder="Enter your name"
              required
              onChange={(e) => setName1(e.target.value)}
            />
          </label>
          <label>
            Partner Name
            <input
              type="text"
              placeholder="Enter your partner name"
              required
              onChange={(e) => setName2(e.target.value)}
            />
          </label>
          <label>
            Couple Name
            <input
              type="text"
              placeholder='Eg.. Virat & Anushka => Virushka'
              
              onChange={(e) => setCoupleName(e.target.value)}
            />
          </label>

          {image1 ? (
            <label>
              <div className="img-spec">
                <p className="field-txt">Your Image</p>
                <span>(recommended aspect ratio of image 1:1)</span>
              </div>
              <div className="show-file">
                <p>{image1.name}</p>
                <input
                  type="file"
                  className="hidden-inpt-again"
                  accept="image/png, image/jpeg, image/gif"

                  required
                  onChange={handleImag1Change}
                />
              </div>
            </label>
          ) : (
            <label>
              <div className="img-spec">
                <p className="field-txt">Your Image</p>
                <span>(recommended aspect ratio of image 1:1)</span>
              </div>
              <input
                type="file"
                className="hidden-inpt"
                required
                accept="image/png, image/jpeg, image/gif"

                onChange={handleImag1Change}
              />
            </label>
          )}

          {image2 ? (
            <label>
              <div className="img-spec">
                <p className="field-txt">Partner Image</p>
                <span>(recommended aspect ratio of image 1:1)</span>
              </div>
              <div className="show-file">
                <p>{image2.name}</p>
                <input
                  type="file"
                  className="hidden-inpt-again"
                  accept="image/png, image/jpeg, image/gif"

                  required
                  onChange={handleImag2Change}
                />
              </div>
            </label>
          ) : (
            <label>
              <div className="img-spec">
                <p className="field-txt">Partner Image</p>
                <span>(recommended aspect ratio of image 1:1)</span>
              </div>
              <input
                type="file"
                className="hidden-inpt"
                accept="image/png, image/jpeg, image/gif"

                required
                onChange={handleImag2Change}
              />
            </label>
          )}
          {coupleImage ? (
            <label>
              <div className="img-spec">
                <p className="field-txt">Couple Image</p>
                <span>(Optional)</span>
              </div>
              <div className="show-file">
                <p>{coupleImage.name}</p>
                <input
                  type="file"
                  className="hidden-inpt-again"
                  accept="image/png, image/jpeg, image/gif"

                  onChange={handleCpleImgChange}
                />
              </div>
            </label>
          ) : (
            <label>
              <div className="img-spec">
                <p className="field-txt">Couple Image</p>
                <span>(Optional)</span>
              </div>
              <input
                type="file"
                className="hidden-inpt"
                required
                accept="image/png, image/jpeg, image/gif"

                onChange={handleCpleImgChange}
              />
            </label>
          )}
          </div>
          <div className="section-Milestone">
          <h2 style={{position:'relative', right:'0', fontSize:'1.5rem'}}>Add your journey milestones</h2>

            {milestones.map((milestone, index) => (
            <div key={index} className="event">
              <label>
                Milestone {index + 1}
                <input
                  type="text"
                  style={{ width: "100%" }}
                  placeholder={`Milestone ${index + 1}`}
                  value={event1[index]}
                  onFocus={() => handleFocus(index)}
                  onChange={(e) =>
                    handleMilestoneChange(index, "event", e.target.value)
                  }
                />
              </label>
              <label  style={{position:'relative'}}>
                Date of Milestone {index + 1}                

                    <div className="delete-milestone">
              <MdOutlineDelete  onClick={()=> handleDeleteMilestone(index)}/>

                    </div>
                <input
                  type="date"
                  className="date"
                  onChange={(e) =>
                    handleMilestoneChange(index, "date", e.target.value)
                  }
                />
              </label>
              {showDropDown(index)}


            </div>
          ))}
          
          <div className="create-btn" style={{width:'42% !important', marginBottom:'1rem'}}>
            <button onClick={handleAddMilestone}>Add Milestone</button>
          </div>
          </div>

          <button type="submit" onClick={handleSubmit}>
            Create Timeline
          </button>
        </div>
      )}
    </div>
    </>
   
  );
};

export default Form;
