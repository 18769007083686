import { createClient } from "@supabase/supabase-js";


// Testing
// const SUPABASE_URL = `https://iekiiqbobtvvbkdlnsho.supabase.co`;
// const SUPABASE_Public_KEY = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imlla2lpcWJvYnR2dmJrZGxuc2hvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTMxNzk4NDEsImV4cCI6MjAyODc1NTg0MX0.Neb9GBiHqN0WN0GNEQSGhC_5yINhor9T_sXkbTJ8uzc`
// const superbase = createClient(SUPABASE_URL, SUPABASE_Public_KEY);


// Production
export const SUPABASE_URL = `https://ngwkupedppzavcmlpmif.supabase.co`;
const SUPABASE_Public_KEY = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5nd2t1cGVkcHB6YXZjbWxwbWlmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDYxOTU1MjAsImV4cCI6MjAyMTc3MTUyMH0.pFnB8-jvykOBN8SwT_vR4jNm0mUD6OBn-j4c8p0MAbo`
const superbase = createClient(SUPABASE_URL, SUPABASE_Public_KEY);
    

export default superbase;