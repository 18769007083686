import React from 'react';
import '../styles/Header.css';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  return (
    <header>
      <a href="https://www.greetsu.com" className="header-logo">
        <img src='rainbow.png' alt='Logo' className='header-img' />
      </a>

        <div style={{display:'flex', alignItems:'center', gap:'0.5rem'}}>
          <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', gap:'0.5rem', position: "relative"}}>
            <h1 className='heading-txt'>Loveto</h1>
            <div className='heading-subtext'>Powered by <img src="logo.svg" alt="logo" height={20} /></div>
          </div>
          
          <p style={{display:'inline', fontSize:'1rem', fontWeight: 'normal'}}>Create your love timeline</p>
        </div>

        {/* <h1 className='heading-txt'>Loveto <p style={{display:'inline', fontSize:'1rem', fontWeight: 'normal'}}>Create your love journey</p></h1> */}
        <hr/>
    </header>
  )
}

export default Header
