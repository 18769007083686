import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBKke0k75RzOUDlV9qWk1QwRDlEHWl6raE",
  authDomain: "loveto-2646a.firebaseapp.com",
  databaseURL: "https://loveto-2646a-default-rtdb.firebaseio.com",
  projectId: "loveto-2646a",
  storageBucket: "loveto-2646a.appspot.com",
  messagingSenderId: "215768739014",
  appId: "1:215768739014:web:97fb0a9e93f042191dbbc7"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const userInfoRef = collection(db, 'userInfo');
