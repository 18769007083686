import React, { useEffect } from "react";
import { useState } from "react";
import { db } from "../config/firebase";
import { collection, getDocs } from "firebase/firestore";
import { docID } from "./Form";
import "../styles/Timeline.css";
import moment from "moment/moment";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import superbase, { SUPABASE_URL } from "../config/superbase";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { WhatsappShareButton } from "react-share";
import * as htmlToImage from "html-to-image";
import { Helmet } from "react-helmet";

const Timeline = () => {
  const [dataFile, setDataFile] = useState([]);

  const [coupleData, setCoupleData] = useState([]);
  const [milestonesData, setMilestonesData] = useState([]);
  const [milestonesSvgs, setMilestonesSvgs] = useState([]);
  const [randomSvgUrls, setRandomSvgUrls] = useState([]);
  const [copyLinkSvgsUrl, setCopyLinkSvgsUrl] = useState([]);
  const [milestonesDataLength, setMilestonesDataLength] = useState();
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const { coupleId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCoupleData = async () => {
      try {
        const { data, error } = await superbase
          .from("CoupleInfo")
          .select("*")
          .eq("coupleId", coupleId)
          .single();
        setCoupleData(data);
        // console.log("data of couple", coupleData);

        if (error) {
          console.error("Error fetching couple data:", error.message);
          return null;
        }

        return data;
      } catch (error) {
        console.error("Error fetching couple data:", error.message);
        return null;
      }
    };

    const fetchMilestoneData = async () => {
      try {
        const { data, error } = await superbase
          .from("MilestonesInRelation")
          .select("*")
          .eq("CoupleId", coupleId);

        data.sort((a, b) => {
          const dateA = new Date(a.Date);
          const dateB = new Date(b.Date);

          // Compare the dates
          return dateA - dateB;
        });

        setMilestonesData(data);

        // console.log("data of milestones", milestonesData);

        if (error) {
          console.error("Error fetching couple data:", error.message);
          return null;
        }

        return data;
      } catch (error) {
        console.error("Error fetching couple data:", error.message);
        return null;
      }
    };

    const fetchMilestonesSvgs = async () => {
      try {
        // Fetch a list of objects (images) in the storage bucket
        const { data, error } = await superbase.storage
          .from("milestoneSvgs")
          .list();

        // console.log("data of svgs", data);

        const svg_Base_Url = `${SUPABASE_URL}/storage/v1/object/public/milestoneSvgs/`;
        const urls = [];
    
       data.map((item) => {
        const svgUrl = `${svg_Base_Url}${item.name}`;
        urls.push(svgUrl);
       });

        setRandomSvgUrls(urls);
        // console.log("random svgs", randomSvgUrls);
       
       

        if (error) {
          throw error;
        }

        // Set the retrieved images in the state
      } catch (error) {
        console.error("Error fetching images:", error.message);
      }
    };

    const fetchCopyLinkSvgs = async () => {
      try {
        // Fetch a list of objects (images) in the storage bucket
        const { data, error } = await superbase.storage
          .from("shareSvgs")
          .list();
        // console.log("data of copy link svgs", data);
        const shareSvgs = data.map((item) => {
          return `${SUPABASE_URL}/storage/v1/object/public/shareSvgs/${item.name}`;
        });
        setCopyLinkSvgsUrl(shareSvgs);
        // console.log("data of copy link svgs", copyLinkSvgsUrl);

        if (error) {
          throw error;
        }

        // Set the retrieved images in the state
      } catch (error) {
        console.error("Error fetching images:", error.message);
      }
    };

    fetchCoupleData();
    fetchMilestoneData();
    fetchMilestonesSvgs();
    fetchCopyLinkSvgs();
  }, [coupleId]);

  useEffect(() => {
    const svg_Base_Url = `${SUPABASE_URL}/storage/v1/object/public/milestoneSvgs/`;
    const urls = [];

    for (let i = 0; i < milestonesDataLength; i++) {
      const svgName = milestonesSvgs[i]?.name;
      const svgUrl = `${svg_Base_Url}${svgName}`;
      urls.push(svgUrl);
    }
    setRandomSvgUrls(urls);
    // console.log("random svgs", randomSvgUrls);
  }, [milestonesSvgs]);

  useEffect(() => {
    // console.log("couple data", coupleData)
    const dataSize = milestonesData.length;
    setMilestonesDataLength(dataSize);
    // console.log("data size", milestonesDataLength);
  }, [milestonesData]);

  const navigateToForm = () => {
    // smooth scroll to top
    window.scrollTo({ top: 0, behavior: "smooth" });

    navigate("/");
  };

  const shareOnWhatsapp = () => {
    const dynamicUrl = window.location.href;
    const message = `Check out this amazing timeline: ${dynamicUrl}`;
    const encodedMessage = encodeURIComponent(dynamicUrl);
    window.open(`https://wa.me/?text=${encodedMessage}`);
  };

  const copyLink = () => {
    const dynamicUrl = window.location.href;
    navigator.clipboard.writeText(dynamicUrl);
    toast.success("Link copied Successfully!");
  };

  // const downloadTimeline = async () => {
  //   const element = document.getElementById("canvas");
  //   htmlToImage
  //     .toPng(element)
  //     .then((dataUrl) => {
  //       const link = document.createElement("a");
  //       link.download = "page.png";
  //       link.href = dataUrl;
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.error("Error converting to image:", error);
  //     });
  // };


  // const downloadTimeline = async () => {
  //   const element = document.getElementById("canvas");

  //   try {
  //     // Convert the HTML element to a PNG data URL
  //     const dataUrl = await htmlToImage.toPng(element);

  //     // Create an image element
  //     const image = new Image();
  //     image.src = dataUrl;

  //     // Wait for the image to load
  //     image.onload = () => {
  //       // Create a canvas element
  //       const canvas = document.createElement("canvas");
  //       const context = canvas.getContext("2d");

  //       // Set canvas dimensions to match the image
  //       canvas.width = image.width;
  //       canvas.height = image.height;

  //       // Draw the image onto the canvas
  //       context.drawImage(image, 0, 0);

  //       // Set the text properties
  //       context.font = "30px Curier New";
  //       context.fillStyle = "red";
  //       context.textAlign = "right";

  //       // Set the text position
  //       const text = "Powered By GreetsU";
  //       const x = canvas.width - 250; // 10 pixels from the right edge
  //       const y = canvas.height - 10; // 10 pixels from the bottom edge

  //       // Draw the text on the canvas
  //       context.fillText(text, x, y);

  //       // Convert the canvas to a PNG data URL
  //       const finalDataUrl = canvas.toDataURL("image/png");

  //       // Create a download link and trigger the download
  //       const link = document.createElement("a");
  //       link.download = "page.png";
  //       link.href = finalDataUrl;
  //       link.click();
  //     };
  //   } catch (error) {
  //     console.error("Error converting to image:", error);
  //   }
  // };

  const downloadTimeline = async () => {
    setDownloadInProgress(true);
    const element = document.getElementById("canvas");
    try {
      // Convert the HTML element to a PNG data URL
      const dataUrl = await htmlToImage.toPng(element);
      // Create an image element for the captured HTML
      const image = new Image();
      image.src = dataUrl;
      // Load the brand logo image
      const logo = new Image();
      logo.src = 'logo.svg'; // Replace with the path to your brand logo
      // Wait for both images to load using Promise.all
      await Promise.all([
        new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = reject;
        }),
        new Promise((resolve, reject) => {
          logo.onload = resolve;
          logo.onerror = reject;
        })
      ]);
      // Create a canvas element
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      // Set canvas dimensions to match the image
      canvas.width = image.width;
      canvas.height = image.height;
      // Draw the captured image onto the canvas
      context.drawImage(image, 0, 0);
      // Draw the brand logo on the canvas
      const logoWidth = 145; // Set the desired width for the logo
      const logoHeight = 54; // Set the desired height for the logo
      const logoX = canvas.width - logoWidth - 30; // Position the logo 10 pixels from the right edge
      const logoY = canvas.height - logoHeight - 10; // Position the logo 10 pixels from the bottom edge
      context.drawImage(logo, logoX, logoY, logoWidth, logoHeight);
      // Convert the canvas to a PNG data URL
      const finalDataUrl = canvas.toDataURL("image/png");
      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.download = "page.png";
      link.href = finalDataUrl;
      link.click();
      setDownloadInProgress(false);
    } catch (error) {
      console.error("Error in generating or downloading the image:", error);
      setDownloadInProgress(false);
    }
  };
  
  return (
    <>
    
    <Helmet>
      

    <meta
    name="keywords" 
    content="Loveto, Love Timeline, Love Journey, Create love memory, Valentine gift, Anniversary gift, Suprise, adorable present, Couples Goal, Gift for Girlfriend, Gift for Boyfriend, Gift for Wife, Gift for Husband"/>
   

    <meta property="og:title"
    content="Loveto - Create your Love Timeline" />


    <meta property="og:type"
    content="website" />
    </Helmet>
    <div className="timeline-main">
      <div className="timeline-compo" id="timeline">
        <div className="canvas" id="canvas">
          <div className="upper-div">
            <img src={coupleData.image1} className="img-1" alt="Pic 1" loading="lazy"/>
            <p className="name-text">
              When {coupleData.name1} met {coupleData.name2}
            </p>
            <img src={coupleData.image2} className="img-1" alt="pic 2" loading="lazy" />
          </div>

          {milestonesData.map((milestone, index) => (
            <React.Fragment key={index}>
              {index % 2 === 0 ? (
                <div className="left-div">
                  <img
                    style={{ width: "90px", height: "105px" }}
                    src={randomSvgUrls[index]}
                    alt="svg"
                  />
                  <div className="align">
                    <p className="main-text">
                      {moment(milestone.Date, "YYYY-MM-DD").format(
                        "Do MMMM YYYY"
                      )}
                    </p>
                    <p className="left-event-txt">{milestone.Milestone}</p>
                  </div>
                </div>
              ) : (
                <div className="right-div">
                  <div className="align">
                    <p className="main-text">
                      {moment(milestone.Date, "YYYY-MM-DD").format(
                        "Do MMMM YYYY"
                      )}
                    </p>
                    <p className="left-event-txt">{milestone.Milestone}</p>
                  </div>
                  <img
                    // style={{ width: "60px", height: "70px" }}
                    style={{ width: "90px", height: "105px" }}
                    src={randomSvgUrls[index]}
                    alt="svg"
                    loading="lazy"
                  />
                </div>
              )}

              {index < milestonesData.length - 1 && (
                <>
                  {index % 2 === 0 ? (
                    <img
                      src="Vector 5.svg"
                      alt="line"
                      className="svg-left-right"
                    />
                  ) : (
                    <img
                      src="Vector 6.svg"
                      alt="line"
                      className="svg-left-right"
                    />
                  )}
                </>
              )}
            </React.Fragment>
          ))}
          {milestonesDataLength % 2 === 0 && milestonesDataLength !== 0 && (
            <img src="Vector 6.svg" alt="line" className="svg-left-right" />
          )}

          <div className="left-div">
            <div className="main-img">
              {coupleData.coupleImage !== null ? (
                <img
                  className="cple-img"
                  src={coupleData.coupleImage}
                  alt="pic 3"
                  loading="lazy"
                />
              ) : (
                <>
                  <img
                    src={coupleData.image1}
                    className="img-left"
                    alt="Pic 1"
                    loading="lazy"
                  />
                  <img
                    src={coupleData.image2}
                    className="img-right"
                    alt="Pic 2"
                    loading="lazy"
                  />
                </>
              )}
            </div>

            <div className="align">
              <p className="main-text-align">
                {
                  coupleData.coupleName === null || coupleData.coupleName === "" ? `${coupleData.name1} & ${coupleData.name2}`:(coupleData.coupleName)
                }
              </p>
              <p className="loveto-text">Loveto for life</p>
            </div>
          </div>
        </div>
      </div>

      <div className="share-secton">
        <p style={{ marginTop: "10px" }}>Share with</p>

        <div className="share-link">
          <WhatsappShareButton
            url={window.location.href}
            title="Check out this amazing timeline"
            separator="::"
          >
            {" "}
            <img src={copyLinkSvgsUrl[1]} alt="share"  loading="lazy"/>
          </WhatsappShareButton>
          <button onClick={copyLink}>
            <img src={copyLinkSvgsUrl[0]} alt="copy" loading="lazy"/>
          </button>
        </div>

        <button 
          className="download-btn"
          disabled={downloadInProgress} 
          onClick={downloadTimeline}>
          {downloadInProgress ? "Generating..." : "Download"}
        </button>

        <button className="back-btn" onClick={navigateToForm}>
          Journey Creation Page
        </button>
      </div>
    </div>
    </>
  );
};

export default Timeline;
